<template>
   <section class="invoice-preview-wrapper">    
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
      <b-row>
        <b-col cols="8">
          <b-card class="invoice-actions" no-body>
            <b-card-body>
              <b-row>
                <b-col cols="12" md="3">
                  <label for="date_start">Fecha Inicio</label>
                  <flat-pickr id="date_start" v-model="filter.start" class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                    placeholder="DD/MM/YYYY" />
                </b-col>
                <b-col cols="12" md="3">
                  <label for="date_start">Fecha Fin</label>
                  <flat-pickr id="date_start" v-model="filter.end" class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                    placeholder="DD/MM/YYYY" />
                </b-col>

                <b-col cols="12" md="6">
                  <div class="demo-inline-spacing">
                    <b-button size="sm" @click="getPrices" variant="primary" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                      <feather-icon icon="SearchIcon" />
                      Buscar
                    </b-button>

                    <!-- <b-button size="sm" v-if="filterPrices.length > 0" @click="printReport" variant="secondary" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                      <feather-icon icon="PrinterIcon" />
                      Imprimir
                    </b-button> -->
                    <price-changes-pdf v-if="filterPrices.length > 0" :prices="filterPrices" :dateStart="filter.start" :dateEnd="filter.end" 
                      @pdf-progress="handlePdfProgress"/>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="8">
          <b-card no-body class="mb-0">
            <div class="m-2 invoice-actions">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                      <b-input-group>
                          <b-form-input size="sm" v-model="search" class="d-inline-block _mr-1" placeholder="Buscar por articulo o clave" autofocus/>
                          <b-input-group-prepend>
                          <b-button size="sm" variant="primary">
                              <feather-icon icon="SearchIcon" />
                          </b-button>
                          </b-input-group-prepend>                                
                      </b-input-group>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                          
                        </div>
                    </b-col>
                </b-row>
            </div>

            <table class="table table-responsive table b-table table-sm b-table-no-border-collapse small">
              <thead>
                <tr>
                  <th>COBRADOR</th>
                  <th>ARTICULO</th>
                  <th>PRECIO</th>
                  <th>ÚLTIMO COSTO</th>
                  <th>PRECIO ACTUAL</th>
                  <th>%</th>
                  <th>PRECIO ANTERIOR</th>
                  <th>%</th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-if="filterPrices.length <= 0">
                <tr>
                  <td colspan="9">
                    <h6 class="text-center mt-1">
                      No hay registros
                    </h6>
                  </td>
                </tr>
              </tbody>
              <tbody v-for="(item, index) in filterPrices" :key="index" v-if="!loading">            
                <tr>
                  <td class="text-nowrap" colspan="9">{{ item.usuario_Nombre }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td><strong>{{ item.clave_Articulo }}</strong></td>
                  <td colspan="6"><strong>{{ item.articulo_Nombre }}</strong></td>
                  <td class="text-capitalize"><strong>{{ formatDate(item.fecha_Hora) }}</strong></td>
                </tr>

                <tr v-for="(price, idx) in item.precios" :key="index+idx">
                  <td colspan="2"></td>
                  <td class="text-nowrap">{{ price.precio_Empresa_Nombre }}</td>
                  <td>{{ formatCurrency(price.ultimo_Costo) }}</td>
                  <td>{{ formatCurrency(price.precio_Nuevo) }}</td>
                  <td>{{ formatoPorcentaje(price.porcentaje_Actual) }}</td>
                  <td>{{ formatCurrency(price.precio_Anterior) }}</td>
                  <td>{{ formatoPorcentaje(price.porcentaje_Anterior) }}</td>
                  <td class="text-capitalize"></td>
                </tr>
              </tbody>
            </table>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardText,
  BCardBody,
  BTable,
  BTableLite,
  BOverlay,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,

} from 'bootstrap-vue'
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store'
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import PriceChangesPdf from '@/@core/components/app-pdf/PriceChangesPdf.vue';

import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridVue } from "ag-grid-vue"; // AG Grid Component

import PricesProvider from '@/providers/Prices';
import moment from 'moment';
import Prices from '@/providers/Prices';
const PricesResource = new PricesProvider()

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BTable,
    BTableLite,
    BOverlay,
    BSpinner,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,

    //
    flatPickr,
    vSelect,
    PriceChangesPdf,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      themeClass: "ag-theme-quartz",
      defaultColDef: {
        editable: false,
        filter: true,
      },
      sideBar: null,
    }
  },
  created() {
    this.sideBar = 'columns';
  },
  methods: {
    handlePdfProgress(evt) {            
      this.loading = true
      if (evt >= 100) {
        this.loading = false
      }
    },
  },
  setup() {

    const loading = ref(false)
    const prices = ref([])
    const search = ref(null)

    const filter = ref({
      start: moment().startOf('month').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    })

    // Column Definitions: Defines the columns to be displayed.
    const colDefs = ref([
      { key: "id", label: "id" },
      { key: "usuario_Id", label: "usuario_Id" },
      { key: "usuario_Nombre", label: "usuario_Nombre" },
      { key: "empresa_Id", label: "empresa_Id" },
      { key: "sucursal_Id", label: "sucursal_Id" },
      { key: "almacen_Id", label: "almacen_Id" },
      { key: "cobrador_Id", label: "cobrador_Id" },
      { key: "articulo_Id", label: "articulo_Id" },
      { key: "articulo_Nombre", label: "articulo_Nombre" },
      { key: "clave_Articulo", label: "clave_Articulo" },
      { key: "precio_Empresa_Id", label: "precio_Empresa_Id" },
      { key: "precio_Empresa_Nombre", label: "precio_Empresa_Nombre" },
      { key: "precio_Anterior", label: "precio_Anterior" },
      { key: "precio_Nuevo", label: "precio_Nuevo" },
      { key: "ultimo_Costo", label: "ultimo_Costo" },
      { key: "fecha_Hora", label: "fecha_Hora" },
      { key: "fecha", label: "fecha" },
      { key: "hora", label: "hora" },
      { key: "porcentaje_Actual", label: "porcentaje_Actual" },
      { key: "porcentaje_Anterior", label: "porcentaje_Anterior" },
    ]);

    const getPrices = async () => {

      const query = {
        idSucursal: store.getters['auth/getBranch'].sucursal_Id,
        idAlmacen: 0, //19,
        fechaInicio: filter.value.start, // null, //'2024-02-19',
        fechaFinal: filter.value.end, // null, //'2024-03-25',
      }

      loading.value = true
      const { data } = await PricesResource.getByParam(query)
      loading.value = false

      if (data.isSuccesful) {

        // Agrupar los precios por artículo
          const preciosPorArticulo = data.data.reduce((acc, precio) => {
          const { articulo_Id, articulo_Nombre, clave_Articulo } = precio;
          if (!acc[articulo_Id]) {
            acc[articulo_Id] = {
              ...precio,
              precios: [precio]
            };
          } else {
            acc[articulo_Id].precios.push(precio);
          }
          // delete acc[articulo_Id].precio_Empresa_Id; // Eliminar el precio_Empresa_Id duplicado
          delete acc[articulo_Id].precio_Empresa_Nombre; // Eliminar el precio_Empresa_Nombre duplicado
          delete acc[articulo_Id].precio_Anterior; // Eliminar el precio_Anterior duplicado
          delete acc[articulo_Id].precio_Nuevo; // Eliminar el precio_Nuevo duplicado
          delete acc[articulo_Id].ultimo_Costo; // Eliminar el ultimo_Costo duplicado
          delete acc[articulo_Id].porcentaje_Actual; // Eliminar el porcentaje_Actual duplicado
          delete acc[articulo_Id].porcentaje_Anterior; // Eliminar el porcentaje_Anterior duplicado
          return acc;
        }, {});

        // Convertir el objeto de precios agrupados nuevamente en un arreglo
        const preciosPorArticuloArray = Object.values(preciosPorArticulo);

        // prices.value = _.orderBy(preciosPorArticuloArray, ['usuario_Nombre', 'articulo_Nombre', 'precio_Empresa_Id'], ['asc', 'asc', 'asc'])

        prices.value = preciosPorArticuloArray.map(articulo => {
            articulo.precios = _.orderBy(articulo.precios, ['usuario_Nombre', 'articulo_Nombre', 'precio_Empresa_Id', 'id'], ['asc', 'asc', 'asc', 'desc']); // Ordenar los precios por fecha_Hora de manera descendente
            return articulo;
        });


      } else {
        loading.value = false
      }
    }

    const filterPrices = computed(() => {
       let result = search.value ? prices.value.filter(item =>
            item.clave_Articulo?.toLowerCase().includes(search.value.toLowerCase()) ||
            item.articulo_Nombre.toLowerCase().includes(search.value.toLowerCase())
        ) : prices.value;
        return result;
    })

    const printReport = () => {
      window.print()
    }

    onMounted(async () => {
      // await getPrices()
    })

    return {
      search,
      loading,
      colDefs,
      prices,
      filter,

      //Computed
      filterPrices,

      //Methods
      getPrices,
      printReport,
    };
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }

    .print-title {
      display: block !important;
    }
  }
}
</style>


<style>
.ag-theme-quartz.large,
.ag-theme-quartz-dark.large {
  --ag-grid-size: 10px;
  --ag-list-item-height: 40px;
  --ag-font-size: 32px;
}

.ag-theme-quartz.compact,
.ag-theme-quartz-dark.compact {
  --ag-grid-size: 3px;
  --ag-list-item-height: 20px;
  --ag-font-size: 10px;
}


.flatpickr-small .flatpickr-input {
    /*font-size: 8px!important; /* Ajusta el tamaño del texto del input */
    padding: 5px; /* Ajusta el padding del input */
    /*width: 120px; /* Ajusta el ancho del input */
}

.flatpickr-input {
    /*width: 150px!important; /* Ajusta el ancho del input */
    height: 30px!important; /* Ajusta la altura del input */
    /*font-size: 7px!important; /* Ajusta el tamaño del texto del input */
}
</style>
